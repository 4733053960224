<template>
  <div>
    <!-- App Bar -->
    <v-app-bar
      absolute
      :dense="$vuetify.breakpoint.mobile"
      color="blue lighten-5"
      class="ma-0"
    >
      <v-icon>mdi-lan-connect</v-icon>
      <v-toolbar-title class="ml-3" v-if="!$vuetify.breakpoint.mobile">
        Dispositivos SSH
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-text-field
        v-if="mode === 'viewer'"
        hide-details
        dense
        filled
        class="text-field-transparent"
        v-model="search"
        persistent-placeholder
        placeholder="Digite as informações de pesquisa"
        prepend-inner-icon="mdi-magnify"
      ></v-text-field>
      <v-spacer></v-spacer>

      <v-btn
        v-if="mode === 'viewer'"
        elevation="2"
        :small="$vuetify.breakpoint.mobile"
        color="primary"
        :icon="$vuetify.breakpoint.mobile"
        @click="loadDispositivo(dispositivo, 'insert')"
        class="pa-2"
        v-tooltip.bottom-end="'Novo dispositivo SSH'"
      >
        Novo <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-btn
        v-if="mode === 'viewer'"
        elevation="2"
        :icon="$vuetify.breakpoint.mobile"
        :small="$vuetify.breakpoint.mobile"
        color="secondary"
        class="pa-2 ml-2"
        @click="setMenuMobile"
        v-tooltip.bottom-end="'Menu geral do sistema'"
      >
        {{ msgButton("Menu") }}
        <v-icon v-if="!$store.state.isMenuVisibleMobile">mdi-menu-down</v-icon>
        <v-icon v-else>mdi-menu</v-icon>
      </v-btn>

      <v-btn
        v-if="mode === 'save' || mode === 'insert'"
        elevation="2"
        :small="$vuetify.breakpoint.mobile"
        color="primary"
        :icon="$vuetify.breakpoint.mobile"
        @click="save"
        class="pa-2"
        :disabled="!valid"
      >
        {{ msgButton("Salvar") }} <v-icon>mdi-check</v-icon>
      </v-btn>
      <v-btn
        v-if="mode === 'remove'"
        elevation="2"
        :small="$vuetify.breakpoint.mobile"
        color="error"
        :icon="$vuetify.breakpoint.mobile"
        @click="remove"
        class="pa-2"
      >
        {{ msgButton("Excluir") }} <v-icon>mdi-delete</v-icon>
      </v-btn>

      <v-btn
        v-if="mode === 'save' || mode === 'insert' || mode === 'remove'"
        elevation="2"
        :small="$vuetify.breakpoint.mobile"
        :icon="$vuetify.breakpoint.mobile"
        @click="reset"
        class="ml-2"
      >
        {{ msgButton("Cancelar") }} <v-icon>mdi-cancel</v-icon>
      </v-btn>
    </v-app-bar>

    <!-- Content Area -->
    <v-sheet class="overflow-y-auto mt-6  ">
      <v-container
        id="containerEdicao"
        fluid
        class="admin-pages"
        :style="heightScroll"
      >
        <div class="api-admin">
          <!-- Form Section -->
          <v-form
            ref="form"
            v-if="mode !== 'viewer'"
            class="mt-4 mt-lg-8"
            v-model="valid"
            lazy-validation
          >
            <!-- Form Fields -->
            <v-row v-if="dispositivo.id">
              <v-col cols="12" md="6" sm="12">
                <v-text-field
                  v-model="dispositivo.id"
                  label="ID"
                  outlined
                  readonly
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- Additional Fields -->
            <v-row>
              <v-col cols="12" md="6" sm="12">
                <v-autocomplete
                  v-model="dispositivo.cnpj_id"
                  :items="cnpjOptions"
                  item-text="cnpj"
                  item-value="id"
                  label="CNPJ ID"
                  required
                  outlined
                  hide-details="auto"
                  :rules="rulesRequired"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <v-text-field
                  v-model="formattedDataValidade"
                  label="Data de Validade"
                  type="date"
                  required
                  outlined
                  hide-details="auto"
                  :rules="rulesRequired"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6" sm="12">
                <v-text-field
                  v-model="dispositivo.nome_dispositivo"
                  label="Nome do Dispositivo"
                  required
                  outlined
                  hide-details="auto"
                  :rules="rulesRequired"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <v-text-field
                  v-model="dispositivo.descricao"
                  label="Descrição"
                  outlined
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6" sm="12">
                <v-text-field
                  v-if="mode !== 'insert'"
                  v-model="dispositivo.token"
                  label="Token"
                  required
                  outlined
                  hide-details="auto"
                  readonly
                  :rules="rulesRequired"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <v-text-field
                  v-if="mode !== 'insert'"
                  v-model="dispositivo.uuid"
                  label="UUID"
                  outlined
                  hide-details="auto"
                  readonly
                  :rules="rulesUUID"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6" sm="12">
                <v-checkbox
                  v-model="dispositivo.ativo"
                  label="Ativo"
                  outlined
                  hide-details="auto"
                  :true-value="true"
                  :false-value="false"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-form>

          <!-- Viewer Mode Section -->
          <div v-if="mode === 'viewer'">
            <hr class="ma-5" />

            <v-data-table
              dense
              :headers="headers"
              :items="dispositivos"
              :search="search"
              item-key="id"
              :items-per-page="15"
              class="elevation-1 pt-3"
              :footer-props="{
                'items-per-page-text': 'Dispositivos por página',
                pageText: '{0}-{1} de {2}',
              }"
              :expanded.sync="expanded"
            >
              <template v-slot:item.action="{ item }">
                <v-btn
                  elevation="2"
                  icon
                  @click="loadDispositivo(item)"
                  class="mr-2"
                  v-tooltip.bottom-end="'Editar dispositivo'"
                  ><v-icon>mdi-pencil</v-icon></v-btn
                >
                <v-btn
                  elevation="2"
                  icon
                  @click="loadDispositivo(item, 'remove')"
                  class=""
                  v-tooltip.bottom-end="'Remover dispositivo'"
                  ><v-icon>mdi-delete</v-icon></v-btn
                >
                <v-btn
                  elevation="2"
                  icon
                  @click="toggleExpand(item)"
                  class=""
                  v-tooltip.bottom-end="'Portas do dispositivo'"
                >
                  <v-icon>mdi-details</v-icon>
                </v-btn>
              </template>
              <template v-slot:item.formattedDataValidade="{ item }">
                {{ item.formattedDataValidade }}
              </template>
              <!-- Slot para linhas expansíveis -->
              <!-- Slot para linhas expansíveis -->
              <template v-slot:expanded-item="{ item }">
                <div style="width: 100%;">
                  <!-- Botão de adicionar nova porta -->
                  <v-btn color="primary" class="mb-3" @click="addNewPort(item)">
                    Novo <v-icon right>mdi-plus</v-icon>
                  </v-btn>

                  <!-- Tabela de portas SSH -->
                  <v-data-table
                    :headers="portHeaders"
                    :items="item.portas"
                    item-key="id"
                    class="pt-0 mb-3 w-100"
                    dense
                  >
                    <template v-slot:item="{ item: port }">
                      <tr v-if="port.isEditing">
                        <td>
                          <v-select
                            v-model="port.porta_host"
                            :items="portDisponivel"
                            density="compact"
                            placeholder="Selecione a Porta Host"
                            dense
                            hide-details
                            outlined
                          ></v-select>
                        </td>
                        <td>
                          <v-text-field
                            density="compact"
                            v-model="port.porta_client"
                            placeholder="Porta Cliente"
                            type="number"
                            dense
                            min="1"
                            max="65535"
                            hide-details
                            outlined
                          ></v-text-field>
                        </td>
                        <td>
                          <v-checkbox
                            v-model="port.ativo"
                            hide-details
                            dense
                            :true-value="true"
                            :false-value="false"
                          ></v-checkbox>
                        </td>
                        <td class="d-flex justify-start align-center">
                          <v-btn
                            color="primary"
                            small
                            class="mr-2"
                            @click="savePort(port, item)"
                          >
                            Salvar
                          </v-btn>
                          <v-btn
                            color="secondary"
                            small
                            @click="cancelEdit(port, item)"
                          >
                            Cancelar
                          </v-btn>
                        </td>
                      </tr>
                      <tr v-else>
                        <td>{{ port.porta_host }}</td>
                        <td>{{ port.porta_client }}</td>
                        <td>{{ port.ativo ? "Ativo" : "Inativo" }}</td>
                        <td class="d-flex justify-start align-center">
                          <v-btn icon small @click="editPort(port)">
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                          <v-btn icon small @click="deletePort(port, item)">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </div>
              </template>
            </v-data-table>
          </div>
        </div>
      </v-container>
    </v-sheet>
    <!-- Diálogo de Confirmação -->
    <v-dialog v-model="confirmDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline">Confirmar Exclusão</v-card-title>
        <v-card-text
          >Tem certeza que deseja remover esta porta SSH?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="confirmDialog = false">
            Cancelar
          </v-btn>
          <v-btn color="green darken-1" text @click="confirmDeletePort">
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { baseApiUrl, showSucess, showError } from "@/global";
import axios from "axios";

export default {
  name: "AdminDispositivoSSH",

  data: function() {
    return {
      valid: false,
      confirmDialog: false,
      portToDelete: null, // Porta a ser deletada
      dispositivoToDelete: null, // Dispositivo ao qual a porta pertence
      items: [
        {
          // Aqui você deve adicionar outros campos necessários para o seu item
          formattedDataValidade: null, // Campo inicializado
        },
      ],

      rulesRequired: [(value) => !!value || "Campo obrigatório"],
      rulesUUID: [
        (value) => (value ? this.isUUID(value) || "UUID inválido" : true),
      ],
      mode: "viewer",
      search: "",
      cnpjOptions: [],
      // heightScroll: "max-height: 1000px;",
      portas: [],
      dispositivo: {},
      dispositivos: [],
      portDisponivel: [],
      expanded: [],
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: true,
          value: "id",
          width: "100px",
        },
        { text: "CNPJ", align: "start", sortable: true, value: "cnpj" },
        {
          text: "Empresa",
          align: "start",
          sortable: true,
          value: "nome_empresa",
        },
        {
          text: "Nome do Dispositivo",
          align: "start",
          sortable: true,
          value: "nome_dispositivo",
          width: "100px",
        },
        {
          text: "Token",
          align: "start",
          sortable: true,
          value: "token",
          width: "250px",
        },
        {
          text: "UUID",
          align: "start",
          sortable: true,
          value: "uuid",
          width: "250px",
        },
        {
          text: "Data de Validade",
          align: "start",
          sortable: true,
          value: "formattedDataValidade",
        },
        { text: "Ativo", align: "start", sortable: true, value: "ativo" },
        { text: "Ações", align: "start", value: "action", sortable: false },
      ],
      portHeaders: [
        {
          text: "Porta Host",
          value: "porta_host",
          width: "120px",
          sortable: false,
        },
        {
          text: "Porta Cliente",
          value: "porta_client",
          width: "120px",
          sortable: false,
        },
        { text: "Status", value: "ativo", width: "50px", sortable: false },
        { text: "Ações", value: "action", sortable: false },
      ],
    };
  },

  methods: {
    toTop() {
      document.getElementById("containerEdicao").scrollIntoView(0, 0);
      window.scrollTo(0, 0);
    },
    toggleExpand(item) {
      const index = this.expanded.indexOf(item);
      this.portas = [];
      if (index > -1) {
        // Fechar a linha expandida
        this.expanded.splice(index, 1);
      } else {
        // Expandir e carregar as portas, se ainda não carregadas
        if (!item.portas) {
          axios
            .get(`${baseApiUrl}/portas_ssh`, {
              params: { dispositivo_id: item.id },
            })
            .then((response) => {
              this.portas = response.data;
              this.$set(item, "portas", response.data);
              this.expanded.push(item); // Expandir a linha após o carregamento
            })
            .catch((err) => console.error(err));
        } else {
          this.expanded.push(item); // Expandir a linha se as portas já estão carregadas
        }
      }
    },
    fetchCnpjOptions() {
      this.cnpjOptions = axios
        .get(`${baseApiUrl}/cnpj_ssh`)
        .then((res) => {
          this.cnpjOptions = res.data;
        })
        .catch((err) => console.error(err));
    },

    loadDispositivos() {
      //
      axios
        .get(`${baseApiUrl}/dispositivo_ssh`)
        .then((res) => {
          // Atualizar cada dispositivo com a data formatada no formato brasileiro
          this.dispositivos = res.data.map((dispositivo) => {
            // Verifica se a data está presente e então formata para DD/MM/YYYY
            const formattedDate = dispositivo.data_validade
              ? this.formatDateBrazil(dispositivo.data_validade)
              : null;

            return {
              ...dispositivo,
              formattedDataValidade: formattedDate,
            };
          });
        })
        .catch((err) => console.error(err));
    },
    setMenuMobile() {
      this.$store.state.isMenuVisibleMobile = !this.$store.state
        .isMenuVisibleMobile;
    },
    formatDateBrazil(date) {
      const [year, month, day] = date.split("T")[0].split("-");
      return `${day}/${month}/${year}`; // Retorna a data no formato DD/MM/YYYY
    },
    save() {
      if (this.$refs.form.validate()) {
        const method = this.dispositivo.id ? "put" : "post";
        const id = this.dispositivo.id ? `/${this.dispositivo.id}` : "";

        axios[method](`${baseApiUrl}/dispositivo_ssh${id}`, this.dispositivo)
          .then((response) => {
            this.dispositivo = { ...response.data };
            showSucess();

            this.reset();
          })
          .catch((error) => {
            showError(error);
          });
      }
    },
    msgButton: function(value) {
      return this.$vuetify.breakpoint.mobile ? "" : value;
    },

    remove() {
      if (this.dispositivo.id) {
        axios
          .delete(`${baseApiUrl}/dispositivo_ssh/${this.dispositivo.id}`)
          .then(() => {
            showSucess("Dispositivo removido com sucesso");
            this.reset();
          })
          .catch(showError);
      }
    },
    addNewPort(dispositivo) {
      if (!dispositivo.portas) {
        this.$set(dispositivo, "portas", []);
      }

      dispositivo.portas.push({
        id: null,
        porta_host: "",
        porta_client: "",
        ativo: true,
        isEditing: true,
      });
      /// buscar portas disponíveis no backend
      axios
        .get(`${baseApiUrl}/portas_ssh_disponiveis`)
        .then((response) => {
          this.portDisponivel = response.data;
        })
        .catch((err) => console.error(err));
    },

    editPort(port) {
      ///gui //
      axios
        .get(`${baseApiUrl}/portas_ssh_disponiveis`)
        .then((response) => {
          this.portDisponivel = response.data;
        })
        .catch((err) => console.error(err));
      this.$set(port, "isEditing", true);
    },

    cancelEdit(port, dispositivo) {
      if (port.id === null) {
        const index = dispositivo.portas.indexOf(port);
        dispositivo.portas.splice(index, 1);
      } else {
        this.$set(port, "isEditing", false);
      }
    },

    confirmDeletePort() {
      const { portToDelete, dispositivoToDelete } = this;

      axios
        .delete(`${baseApiUrl}/portas_ssh/${portToDelete.id}`)
        .then(() => {
          const index = dispositivoToDelete.portas.findIndex(
            (p) => p.id === portToDelete.id
          );
          if (index !== -1) {
            // Remove a porta do array portas do dispositivo
            dispositivoToDelete.portas.splice(index, 1);
          }
          showSucess("Porta SSH removida com sucesso.");
        })
        .catch((error) => {
          showError("Erro ao remover a porta SSH.");
          console.error(error);
        })
        .finally(() => {
          this.confirmDialog = false;
          this.portToDelete = null;
          this.dispositivoToDelete = null;
        });
    },

    savePort(port, dispositivo) {
      port.dispositivo_id = dispositivo.id;

      // Chamada para salvar a porta via API:

      axios["post"](`${baseApiUrl}/portas_ssh`, port)
        .then((response) => {
          port.id = response.data.id;
          showSucess();
          port.isEditing = false;
          this.$set(port, "isEditing", false);
          //dispositivo.portas.push(port);
          //this.reset();
        })
        .catch((error) => {
          showError(error);
        });
    },

    deletePort(port, dispositivo) {
      this.confirmDialog = true;
      this.portToDelete = port;
      this.dispositivoToDelete = dispositivo;
      //   const index = dispositivo.portas.indexOf(port);
      //   dispositivo.portas.splice(index, 1);
      // Chamada para deletar a porta via API:
      // axios.delete(`${baseApiUrl}/portas_ssh/${port.id}`).then(...);
    },

    calculateExpirationDate() {
      const today = new Date();
      return new Date(today.setFullYear(today.getFullYear() + 5))
        .toISOString()
        .substring(0, 10);
    },
    loadDispositivo(dispositivo, mode = "save") {
      this.mode = mode;

      if (mode === "insert") {
        this.dispositivo = {
          ...dispositivo,
          data_validade: this.calculateExpirationDate(),
          ativo: true, // Definindo ativo como true por padrão
        };
      } else {
        this.dispositivo = { ...dispositivo };
      }
      this.toTop();
    },

    reset() {
      this.$refs.form.reset();
      this.dispositivo = {
        ativo: true,
        data_validade: this.calculateExpirationDate(),
      };

      this.loadDispositivos();
      this.mode = "viewer";
    },

    isUUID(uuid) {
      const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
      return regex.test(uuid);
    },
  },

  mounted() {
    this.loadDispositivos();
    this.fetchCnpjOptions();
  },
  computed: {
    formattedDataValidade: {
      get() {
        return this.dispositivo.data_validade
          ? this.dispositivo.data_validade.split("T")[0]
          : null;
      },
      set(newValue) {
        this.dispositivo.data_validade = newValue;
      },
    },
  },
};
</script>

<style>
.text-field-transparent .v-input__slot {
  background: transparent !important;
}
</style>
